/* eslint-disable prettier/prettier */
/* eslint-disable security/detect-unsafe-regex */
import React, {useState, useEffect, useRef, useContext} from 'react'
import {
  Button,
  Card,
  Carousel,
  Checkbox,
  Col,
  Descriptions,
  Form,
  List,
  message,
  Modal,
  Popover,
  Space,
  Spin,
  Tag,
  Typography
} from 'antd'
import {deleteCart, getAppCart, getCoupon, postCartIteReplicate, postCartReplicate, putCart} from '../../../api/cart'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionOutlined
} from '@ant-design/icons'
import {ActionType, BasicTableModalRef} from '../../../components/common/BasicTableModal'
import PageContext from '../../../contexts/PageContext'
import {CheckboxChangeEvent} from 'antd/es/checkbox'
import styled from 'styled-components'
import {getOrderSheet, postOrderSheet} from '../../../api/orderSheets'
import OrderModal from './components/orderModal'
import ImageView from '../../../components/ImageView'
import {useRecoilState, useRecoilValue} from 'recoil'
import {cartTotalState} from '../../../states/cartTotal.state'
import ProductModal from './components/ProductModal'
import IteProductModal from './components/IteProductModal'
import {getProduct, getProducts} from '../../../api/products'
import {userState} from '../../../states/user.state'
import dayjsKorean from '../../../libs/DayjsKorean'
import {getProductOrderType} from '../../../api/productOrderTypes'
import {getProductBrands} from '../../../api/productBrands'
import {getVendor} from '../../../api/vendors'

function Cart() {
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const {state, apiHandler} = useContext(PageContext)
  const modalRef = useRef<BasicTableModalRef>(null)
  const iteModalRef = useRef<BasicTableModalRef>(null)
  const orderModalRef = useRef<BasicTableModalRef>(null)
  const [quantities, setQuantities] = useState({})
  const [data, setData] = useState<any>([])
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [, setCartTotal] = useRecoilState(cartTotalState)
  const user: any = useRecoilValue(userState)
  const [checkedList, setCheckedList] = useState<number[]>([])
  const [checkAll, setCheckAll] = useState(false)
  const [coupons, setCoupons] = useState<any>([])
  const [carts, setCarts] = useState<any>([])
  const [brands, setBrands] = useState<any>([])
  const [couponPopOpen, setCouponPopOpen] = useState<any>(-1)
  const [selectedCoupons, setSelectedCoupons] = useState<any>({})
  const [checkedCouponList, setCheckedCouponList] = useState<number[]>([])
  const [couponLoading, setCouponLoading] = useState<boolean>(false)

  async function onFetchCoupons(selectedCartId) {
    setCouponLoading(true)
    const couponIds = carts.filter(({cartId}) => selectedCartId === cartId).map(({couponId}) => couponId)
    const sheetCoupons = coupons.filter(({coupon: {id}}) => couponIds.includes(id))

    // sheetCoupons.forEach((coupon) => {
    //   if (!uniqueCoupons.find((uniqueCoupon) => uniqueCoupon.coupon.id === coupon.coupon.id)) uniqueCoupons.push(coupon)
    // })

    // setCoupons(
    //   uniqueCoupons.filter(
    //     (coupon) =>
    //       coupon.isUnlimited ||
    //       !coupons.find(({coupon: {id}}) => id === coupon.coupon.id) ||
    //       coupons.find(({coupon: {id}, linkedCart}) => id === coupon.coupon.id && cartIds.includes(linkedCart))
    //   )
    // )

    setCoupons(sheetCoupons)
    setCouponLoading(false)
  }

  async function onFetchBrands() {
    const {data: brands} = await getProductBrands()
    setBrands(brands)
  }

  async function onFetch() {
    try {
      setLoading(true)
      const {data, total} = await getAppCart()
      const {coupons: notExistedCoupons, carts} = await getCoupon({cartIds: data.map(({id}) => id)})

      setCoupons(notExistedCoupons)
      setCarts(carts ? carts.filter(({couponId}) => !!couponId) : [])
      setData(data)
      setCartTotal(total)
      setCheckedList([])
      setCheckAll(false)
      setQuantities((prev) => {
        data.forEach((item) => {
          prev[item.id] = item.quantity
        })
        return prev
      })
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  async function handleDelete(e: React.MouseEvent<HTMLElement, MouseEvent>, ids: number[]) {
    e.stopPropagation()
    await deleteCart(ids)
    const res = await getAppCart()
    setCartTotal(res.total)
    message.success('성공적으로 적용되었습니다.')
    onFetch()
  }

  async function showOrderModal() {
    if (orderModalRef && orderModalRef.current) {
      let nowCartId = -1
      try {
        const {coupons: vendorCoupons} = await getVendor()
        const {id} = await postOrderSheet({
          carts: checkedList.map((id) => {
            const notExpiredCouponIds: number[] = selectedCoupons[id].filter(
              (couponId) => vendorCoupons.find(({coupon: {id}}) => id === couponId)?.status === 'registered'
            )
            let selectedCouponsTmp = {...selectedCoupons}

            Object.keys(selectedCouponsTmp).forEach((key) => {
              if (!checkedList.includes(+key)) delete selectedCouponsTmp[key]
            })

            Object.values(selectedCouponsTmp).map((coupons: any) =>
              coupons.forEach((couponId2) => {
                if (
                  !!carts.find(({cartId, couponId}) => cartId === id && couponId === couponId2) &&
                  !notExpiredCouponIds.includes(couponId2)
                )
                  notExpiredCouponIds.push(couponId2)
              })
            )
            nowCartId = id
            return {id, quantity: quantities[id], couponIds: notExpiredCouponIds}
          })
        })
        const record = await getOrderSheet(id)
        orderModalRef.current.showModal({type: 'show', record: {...record, id}})
      } catch (e: any) {
        if (e.response?.data.message === 'quantity_is_not_enough') {
          Modal.confirm({
            content: '재고가 부족하여 구매에 실패했습니다. 해당 상품을 장바구니에서 삭제하시겠습니까?',
            title: '재고 부족',
            okText: '삭제',
            cancelText: '취소',
            onOk: async () => {
              await deleteCart([nowCartId])
              const res = await getAppCart()
              setCartTotal(res.total)
              message.success('성공적으로 적용되었습니다.')
              onFetch()
            }
          })
        } else {
          Modal.error({
            content: `주문 도중 오류가 발생했습니다. 관리자에게 문의 부탁드립니다.
          ${e.response.status} : ${e.response.data.message}`,
            title: '주문 중 오류'
          })
        }
      }
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  const handleCheck = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setCheckedList((prev) => [...prev, e.target.value])
      setCheckAll(checkedList.length + 1 === data.length)
    } else {
      checkedList.splice(
        checkedList.findIndex((checkedId) => checkedId === e.target.value),
        1
      )
      setCheckedList([...checkedList])
      setCheckAll(false)
    }
  }

  const handleCouponCheck = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setCheckedCouponList((prev) => {
        const checked = [...prev, e.target.value]
        const uniqueArr = checked.reduce((acc, cur) => {
          if (acc.includes(cur)) {
            return acc
          } else {
            return [...acc, cur]
          }
        }, [])
        return uniqueArr
      })
    } else {
      setCheckedCouponList(checkedCouponList.filter((checkedId) => checkedId !== e.target.value))
    }
  }

  const handleCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? data.map((cartItem) => cartItem.id) : [])
    setCheckAll(e.target.checked)
  }

  async function showTableModal(e, data?, index?, list?, color?) {
    if (modalRef && modalRef.current) {
      const res = await getProductOrderType(data.info.orderType.id)
      if (data) modalRef.current.showModal({type: 'show', record: {orderType: res, data, list, color}})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  function showTableIteModal(e, data?) {
    if (iteModalRef && iteModalRef.current) {
      if (data)
        iteModalRef.current.showModal({
          type: 'show',
          record: {
            data
          }
        })
      else iteModalRef.current.showModal({type: 'add'})
    }
  }

  // function getCouponedPrice(price, item, couponList) {
  //   let priceValue = price
  //   const priceCoupons: number[] = []

  //   if (couponList && couponList.length) {
  //     couponList.forEach((couponId) => {
  //       const coupon = coupons.find(({coupon, linkedCart}) =>
  //         linkedCart ? linkedCart === item.id && coupon.id === couponId : coupon.id === couponId
  //       )?.coupon
  //       if (coupon && coupon.discounts.length) {
  //         if (coupon?.type !== 'price')
  //           priceValue =
  //             +coupon.discounts[0]?.applyPriceGroup.prices[0].price * (couponPopOpen.quatity || item.quantity)
  //         else {
  //           const foundIndex = coupon.discounts?.findIndex(({priceGroups}) => {
  //             const foundBundleSpecial = coupons.find(({coupon: {type}}) => type !== 'price')?.coupon
  //             if (foundBundleSpecial && coupon?.type !== 'price')
  //               return priceGroups[0].name === foundBundleSpecial.discounts[0]?.applyPriceGroup.name
  //             else return priceGroups[0].name === user.priceGroup.name
  //           })
  //           if (foundIndex !== -1)
  //             priceCoupons.push(coupon.discounts[foundIndex].value * (couponPopOpen.quatity || item.quantity))
  //         }
  //       }
  //     })

  //     priceCoupons.forEach((discountValue) => {
  //       priceValue -= discountValue
  //     })
  //   }

  //   return priceValue > 0 ? priceValue : 0
  // }

  function getCouponedPrice(price, item, couponList) {
    let priceValue = price
    const priceCoupons: number[] = []

    if (coupons.length && couponList && couponList.length) {
      couponList.forEach((targetedCouponId) => {
        const couponItem = coupons.find(({coupon, linkedCart}) =>
          linkedCart ? linkedCart === item.id && coupon.id === targetedCouponId : coupon.id === targetedCouponId
        )
        if (couponItem?.coupon.type !== 'price')
          priceCoupons.push(
            carts.find(({cartId, couponId}) => cartId === item.id && targetedCouponId === couponId)?.couponPrice *
              item.quantity
          )
        else
          priceCoupons.push(
            price -
              carts.find(({cartId, couponId}) => cartId === item.id && targetedCouponId === couponId)?.couponPrice *
                item.quantity
          )
      })

      priceCoupons.forEach((discountValue) => {
        priceValue -= price - discountValue
      })
    }

    return priceValue > 0 ? priceValue : 0
  }

  useEffect(() => {
    onFetch()
    onFetchBrands()
  }, [])

  useEffect(() => {
    if (Object.keys(couponPopOpen).length) onFetchCoupons(couponPopOpen.id)
  }, [couponPopOpen])

  useEffect(() => form.setFieldsValue(data), [data])

  useEffect(() => {
    const selectedCoupons: any = {}

    data.forEach(({coupons: selectedCouponsData, id}) => {
      selectedCoupons[id] = selectedCouponsData
        .map(({id}) => id)
        .filter((selectedCouponId) => coupons.find(({coupon: {id}}) => id === selectedCouponId))
    })

    setSelectedCoupons(selectedCoupons)
  }, [data])

  return (
    <Space direction="vertical" style={{width: '100%'}}>
      <List
        loading={loading}
        itemLayout="vertical"
        size="default"
        pagination={{pageSize: 5}}
        dataSource={data}
        header={
          <ListHeader>
            <CheckAllArea>
              <Checkbox onChange={handleCheckAllChange} checked={checkAll}>
                모두 선택
              </Checkbox>
            </CheckAllArea>
            <Space>
              <CheckedTargetButton
                disabled={!checkedList.length}
                type="primary"
                onClick={(e) => handleDelete(e, checkedList)}
                icon={<DeleteOutlined />}
                danger
              >
                삭제
              </CheckedTargetButton>
              <CheckedTargetButton
                disabled={!checkedList.length}
                type="primary"
                onClick={showOrderModal}
                icon={<CheckCircleOutlined />}
              >
                구매
              </CheckedTargetButton>
            </Space>
          </ListHeader>
        }
        renderItem={(item: any, index: number) => (
          <List.Item key={item.id}>
            <List.Item.Meta
              avatar={<Checkbox value={item.id} checked={checkedList.includes(item.id)} onChange={handleCheck} />}
              description={
                <div style={{display: 'flex'}}>
                  <ImageView src={item?.productInfo?.titleImage} width={172} height={172} isBorder />
                  <div style={{width: 'calc(100% - 148px)', marginLeft: 20}}>
                    <ListMiddle>
                      <div>
                        <SubTitle>{item?.productInfo?.brand}</SubTitle>
                        <Title level={4}>{item?.productInfo?.name}</Title>
                        {user.isPricePublic && (
                          <Space style={{margin: '-10px 0 10px 0'}}>
                            {loading ? (
                              <Spin />
                            ) : (
                              <Space
                                style={{
                                  marginRight: -5,
                                  fontSize: 18,
                                  fontWeight: 'bold'
                                }}
                              >
                                {item?.amount !== getCouponedPrice(item?.amount, item, selectedCoupons[item?.id]) && (
                                  <div
                                    style={{
                                      color: '#b7b7b7',
                                      textDecoration: 'line-through'
                                    }}
                                  >
                                    {item?.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </div>
                                )}
                                <div
                                  style={{
                                    color: process.env.REACT_APP_THEME_COLOR
                                  }}
                                >
                                  {getCouponedPrice(item?.amount, item, selectedCoupons[item?.id])
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </div>
                              </Space>
                            )}
                            <div style={{marginTop: 5}}>원</div>
                          </Space>
                        )}
                        <Descriptions column={2} size="small" bordered>
                          <Descriptions.Item label="제품 유형">{item?.productInfo?.orderType}</Descriptions.Item>
                          <Descriptions.Item label="수량">{item?.quantity}</Descriptions.Item>
                          <Descriptions.Item label="쿠폰">
                            <Space>
                              {selectedCoupons && selectedCoupons[item.id] ? (
                                selectedCoupons[item.id].map((couponId) => {
                                  const selectedCouponItem = coupons.find(({coupon: {id}}) => id === couponId)
                                  if (selectedCouponItem) return <Tag>{selectedCouponItem.coupon.name}</Tag>
                                  else return <></>
                                })
                              ) : (
                                <></>
                              )}
                              <Button
                                type="primary"
                                size="small"
                                shape="round"
                                icon={<EditOutlined />}
                                onClick={() => {
                                  setCheckedCouponList(selectedCoupons[item.id] || [])
                                  setCouponPopOpen(item)
                                }}
                              >
                                쿠폰 설정
                              </Button>
                            </Space>
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                      {item?.productInfo?.orderType === 'ITE' && (
                        <Descriptions
                          title={
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                              <div>옵션</div>
                              <div style={{display: 'flex', gap: 8}}>
                                <Button
                                  style={{fontSize: 12, padding: 0}}
                                  type="link"
                                  icon={<EditOutlined />}
                                  onClick={async (e) => {
                                    setModalActions(['show'])
                                    try {
                                      const res = await getProduct(item.productInfo?.id)
                                      showTableIteModal(e, {
                                        ...res,
                                        ...item.productInfo,
                                        ...item
                                      })
                                    } catch (e) {}
                                  }}
                                >
                                  수정
                                </Button>
                                <Button
                                  style={{fontSize: 12, padding: 0}}
                                  type="link"
                                  icon={<CopyOutlined />}
                                  onClick={async (e) => {
                                    try {
                                      await postCartIteReplicate(item.id)
                                      message.success('복사가 완료되었습니다.')
                                      onFetch()
                                    } catch (e) {
                                      message.error('해당 상품의 수량이 부족합니다.')
                                    }
                                  }}
                                >
                                  복사
                                </Button>
                              </div>
                            </div>
                          }
                          column={2}
                          bordered
                          size="small"
                          style={{width: 500}}
                        >
                          <Descriptions.Item label="착용자명">{item?.iteOptionInfo?.name}</Descriptions.Item>
                          <Descriptions.Item label="보장구 바코드 사용">
                            {item?.iteOptionInfo.isBarcode ? 'O' : 'X'}
                          </Descriptions.Item>
                          <Descriptions.Item label="방향">
                            {item?.iteOptionInfo?.direction === 'all'
                              ? '양쪽'
                              : item?.iteOptionInfo?.direction === 'left'
                                ? '왼쪽'
                                : '오른쪽'}
                          </Descriptions.Item>
                        </Descriptions>
                      )}
                      {item?.productInfo?.orderType !== 'ITE' &&
                      !item?.basicOptionInfo?.color &&
                      !item?.basicOptionInfo?.charge &&
                      !item?.basicOptionInfo?.receivers?.length ? (
                        <></>
                      ) : (
                        <>
                          {/* <Button
                            style={{fontSize: 12, padding: 0}}
                            type="link"
                            icon={<CopyOutlined />}
                            onClick={async (e) => {
                              await postCartReplicate(item.id)
                              message.success('복사가 완료되었습니다.')
                              onFetch()
                            }}
                          >
                            복사
                          </Button> */}
                          {item?.productInfo?.orderType !== 'ITE' && (
                            <Descriptions
                              title={
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                  <div>옵션</div>
                                  <div style={{display: 'flex', gap: 8}}>
                                    <Button
                                      style={{fontSize: 12, padding: 0}}
                                      type="link"
                                      icon={<EditOutlined />}
                                      onClick={async (e) => {
                                        setModalActions(['show'])

                                        const res = await getProduct(item.productInfo?.id)
                                        const params: any = {
                                          finalValueId: res.finalValue.id,
                                          brand: item.productInfo.brand,
                                          orderType: item.productInfo.orderType
                                        }
                                        const list = await getProducts(params)
                                        showTableModal(e, {
                                          ...res,
                                          ...list.data[0],
                                          existingOpt: {
                                            ...item?.basicOptionInfo,
                                            quantity: item?.quantity,
                                            cartId: item?.id,
                                            coupons: item?.coupons
                                          }
                                        })
                                      }}
                                    >
                                      수정
                                    </Button>
                                    <Button
                                      style={{fontSize: 12, padding: 0}}
                                      type="link"
                                      icon={<CopyOutlined />}
                                      onClick={async (e) => {
                                        try {
                                          await postCartReplicate(item.id)
                                          message.success('복사가 완료되었습니다.')
                                          onFetch()
                                        } catch (e) {
                                          message.error('해당 상품의 수량이 부족합니다.')
                                        }
                                      }}
                                    >
                                      복사
                                    </Button>
                                  </div>
                                </div>
                              }
                              column={Object.keys(item.basicOptionInfo).length > 2 ? 2 : 1}
                              bordered
                              size="small"
                              style={{width: 500}}
                            >
                              {item?.basicOptionInfo?.productGrade && (
                                <Descriptions.Item label="등급">
                                  {item?.basicOptionInfo?.productGrade?.name}
                                </Descriptions.Item>
                              )}
                              {item?.basicOptionInfo?.color && (
                                <Descriptions.Item label="컬러">
                                  <Space>
                                    <ColorBox code={item?.basicOptionInfo?.colorHex} />
                                    {item?.basicOptionInfo?.color}
                                  </Space>
                                </Descriptions.Item>
                              )}
                              {item?.basicOptionInfo?.hooks?.length && (
                                <Descriptions.Item label="후크">
                                  <Space>
                                    <ColorBox code={item?.basicOptionInfo?.hooks[0].hex} />
                                    {item?.basicOptionInfo?.hooks[0].name}
                                  </Space>
                                </Descriptions.Item>
                              )}
                              {item?.basicOptionInfo?.charges?.length && (
                                <Descriptions.Item label="충전기">
                                  {item?.basicOptionInfo?.charges.map((name) => <Tag>{name}</Tag>)}
                                </Descriptions.Item>
                              )}
                              {item?.basicOptionInfo?.receivers?.length && (
                                <Descriptions.Item label="리시버">
                                  {item?.basicOptionInfo?.receivers?.map((receiver, index) =>
                                    receiver?.productReceiverDirection ? (
                                      <Tag>
                                        <>
                                          {receiver?.productReceiverDirection.name}
                                          {' / '}
                                          {receiver?.productReceiverLength.name}
                                          {receiver?.productReceiverPower &&
                                            ` / ${receiver?.productReceiverPower.name}`}
                                        </>
                                      </Tag>
                                    ) : receiver?.product?.productReceiverDirection ? (
                                      <Tag>
                                        <>
                                          {receiver?.product?.productReceiverDirection.name}
                                          {' / '}
                                          {receiver?.product?.productReceiverLength.name}
                                          {receiver?.product?.productReceiverPower &&
                                            ` / ${receiver?.product?.productReceiverPower.name}`}
                                        </>
                                      </Tag>
                                    ) : (
                                      <></>
                                    )
                                  )}
                                </Descriptions.Item>
                              )}
                            </Descriptions>
                          )}
                        </>
                      )}
                    </ListMiddle>
                  </div>
                </div>
              }
            />
          </List.Item>
        )}
      />
      <Modal
        width={1100}
        title={
          <Space>
            <div>쿠폰 적용</div>
            <Popover
              placement="topLeft"
              content={
                <span>
                  충전기 제외 할인의 경우 장바구니에서 적용 및 적용해제 하실 수 없으며,
                  <br />
                  수정을 원하실 경우 본 장바구니 항목을 삭제 후 해당 상품의 상세창에서 적용하셔야 합니다.
                </span>
              }
            >
              <Button shape="circle" icon={<QuestionOutlined />} />
            </Popover>
          </Space>
        }
        open={Object.keys(couponPopOpen).length as any}
        onOk={async () => {
          setSelectedCoupons((prev) => {
            prev[couponPopOpen.id] = checkedCouponList
            return prev
          })
          if (couponPopOpen.productInfo.orderType !== 'ITE')
            await putCart(couponPopOpen.id, {couponIds: checkedCouponList})
          // await postOrderSheet({
          //   carts: [{id: couponPopOpen.id, quantity: couponPopOpen.quantity, couponIds: checkedCouponList}]
          // })
          setCheckedCouponList([])
          setCouponPopOpen({})
          await onFetch()
        }}
        onCancel={async () => {
          setCheckedCouponList([])
          setCouponPopOpen({})
          await onFetch()
        }}
      >
        {couponLoading ? (
          <Spin />
        ) : (
          <StyledCarousel
            arrows
            prevArrow={<Button type="link" shape="circle" icon={<ArrowLeftOutlined />} />}
            nextArrow={<Button type="link" shape="circle" icon={<ArrowRightOutlined />} />}
            dots={false}
            slidesToShow={4}
            style={{margin: '20px 0', display: 'flex', gap: 10}}
          >
            {coupons
              ?.filter(
                ({coupon}) =>
                  ((coupon.isEven && couponPopOpen?.quantity % 2 === 0) || !coupon.isEven) &&
                  (((coupon.type === 'special' || coupon.type === 'bundle') &&
                    brands.find(({id}) => coupon.brand?.id === id || coupon.brandId === id)?.name ===
                      couponPopOpen?.productInfo?.brand) ||
                    (coupon.type === 'price' &&
                      brands.find(({id}) => coupon.brand?.id === id || coupon.brandId === id)?.name ===
                        couponPopOpen?.productInfo?.brand))
              )
              .map(({coupon: {status, ...coupon}}, index) => (
                <div>
                  <StyledCard
                    size="small"
                    title={
                      <Space>
                        <Checkbox
                          value={coupon.id}
                          disabled={
                            coupon.isCharge ||
                            // If a special or bundle coupon is already selected
                            (checkedCouponList.find((couponId) => {
                              const existingCoupon = coupons.find(({coupon}) => coupon.id === couponId)
                              return (
                                existingCoupon &&
                                (existingCoupon.coupon.type === 'special' || existingCoupon.coupon.type === 'bundle')
                              )
                            }) &&
                              // And the current coupon is special or bundle type
                              (coupon.type === 'special' || coupon.type === 'bundle') &&
                              // And the current coupon is not already in the list
                              !checkedCouponList.includes(coupon.id)) ||
                            (checkedCouponList.find(
                              (couponId) => coupons.find(({id}) => couponId === id)?.type === 'price'
                            ) &&
                              coupon.type === 'price' &&
                              !checkedCouponList.includes(coupon.id)) ||
                            (!checkedCouponList.includes(coupon.id) &&
                              ((coupon.bundleCount &&
                                checkedCouponList.filter((couponId) => {
                                  return coupons.find(({id}) => id === couponId)?.type === 'bundle'
                                }).length >= coupon.bundleCount) ||
                                getCouponedPrice(
                                  couponPopOpen.price?.price * couponPopOpen?.quantity,
                                  couponPopOpen,
                                  checkedCouponList
                                ) < 0)) ||
                            (coupon.bundleCount &&
                              Object.keys(selectedCoupons)
                                .filter((cartId) => selectedCoupons[cartId].includes(coupon.id))
                                .map((cartId) =>
                                  cartId === `${couponPopOpen.id}`
                                    ? checkedCouponList.includes(coupon.id)
                                      ? couponPopOpen.quantity
                                      : 0
                                    : data.find(({id}) => Object.keys(selectedCoupons).includes(`${id}`))?.quantity || 0
                                )
                                .reduce((accumulator, currentValue) => {
                                  return accumulator + currentValue
                                }, 0) >= coupon.bundleCount &&
                              !Object.keys(selectedCoupons).includes(`${couponPopOpen.id}`))
                          }
                          checked={checkedCouponList.includes(coupon.id)}
                          onChange={handleCouponCheck}
                        />
                        {coupon.id}
                        <Typography.Text mark>
                          {coupon.type === 'bundle' && '번들'}
                          {coupon.type === 'price' && '금액할인'}
                          {coupon.type === 'special' && '특별할인'}
                        </Typography.Text>
                        <Typography.Text>{coupon.name}</Typography.Text>
                      </Space>
                    }
                  >
                    <Descriptions column={1}>
                      {coupon.endDate ? (
                        <Descriptions.Item label="사용기간">
                          {`${dayjsKorean(coupon.startDate).format('YYYY/MM/DD')} ~ ${dayjsKorean(
                            coupon.endDate
                          ).format('YYYY/MM/DD')}`}
                        </Descriptions.Item>
                      ) : (
                        <Descriptions.Item label="사용 시작일">
                          {dayjsKorean(coupon.startDate).format('YYYY/MM/DD')}
                        </Descriptions.Item>
                      )}
                      {coupon.brandId && (
                        <Descriptions.Item label="사용가능 브랜드">
                          <Tag>{brands.find(({id}) => id === coupon.brandId)?.name}</Tag>
                        </Descriptions.Item>
                      )}
                      {coupon.brand && (
                        <Descriptions.Item label="사용가능 브랜드">
                          <Tag>{brands.find(({id}) => id === coupon.brand.id)?.name}</Tag>
                        </Descriptions.Item>
                      )}
                      {coupon.bundleCount && (
                        <Descriptions.Item label="구입 수량 조건">
                          <Tag>
                            {Object.keys(selectedCoupons)
                              .filter((cartId) => selectedCoupons[cartId].includes(coupon.id))
                              .map((cartId) =>
                                cartId === `${couponPopOpen.id}`
                                  ? checkedCouponList.includes(coupon.id)
                                    ? couponPopOpen.quantity
                                    : 0
                                  : data.find(({id}) => Object.keys(selectedCoupons).includes(`${id}`))?.quantity || 0
                              )
                              .reduce((accumulator, currentValue) => {
                                return accumulator + currentValue
                              }, 0)}
                            /{coupon.bundleCount}
                          </Tag>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                    {coupon.isEven && <Typography.Text type="danger">* 짝수 수량에서만 사용 가능</Typography.Text>}
                  </StyledCard>
                </div>
              ))}
          </StyledCarousel>
        )}
        {user.isPricePublic && (
          <Col span={24} style={{marginBottom: 20}}>
            {couponPopOpen.price &&
              typeof couponPopOpen.price.price === 'number' &&
              couponPopOpen.price.price !== -1 && (
                <Card>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>선택 된 쿠폰이 적용 된 가격은 다음과 같습니다.</div>
                    <Space>
                      <div style={{color: process.env.REACT_APP_THEME_COLOR, fontSize: 20, fontWeight: 'bold'}}>
                        {getCouponedPrice(couponPopOpen.amount, couponPopOpen, checkedCouponList)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </div>
                      원
                    </Space>
                  </div>
                </Card>
              )}
          </Col>
        )}
      </Modal>
      <ProductModal actions={modalActions} ref={modalRef} onAction={onModalAction} onFetch={onFetch} />
      <IteProductModal actions={modalActions} ref={iteModalRef} onAction={onModalAction} onFetch={onFetch} />
      <OrderModal checkedList={checkedList} actions={modalActions} ref={orderModalRef} onAction={onModalAction} />
    </Space>
  )
}

const StyledCarousel = styled(Carousel)`
  margin: 10px 0 20px 0;
  overflow: hidden;
  height: 180px;

  .slick-list {
    min-width: 875px;
  }

  .slick-arrow {
    position: initial;
    height: 35px !important;
    border: 1px solid #dedede !important;
    color: rgba(0, 0, 0, 0.85) !important;
    visibility: visible !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    margin-top: 70px;

    .slick-prev {
      margin-right: 120px !important;
    }

    .slick-next {
      margin-left: 120px !important;
    }
  }
`

const StyledCard = styled(Card)`
  margin-right: 10px;
  height: 180px;
  border-radius: 10px;
`

const CheckAllArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px;
  background: ${process.env.REACT_APP_THEME_COLOR};
  border-radius: 10px 0 0 10px;
  box-shadow: rgba(29, 35, 41, 0.1) 2px 0px 8px 0px;
  border-radius: 10px;

  .ant-checkbox-wrapper {
    color: white;
    font-weight: 600;
  }
`

const ListHeader = styled(Space)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ListMiddle = styled(Space)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CheckedTargetButton = styled(Button)`
  height: 42px;

  border-radius: 10px;
  font-size: 15px;
  box-shadow: rgba(29, 35, 41, 0.1) 2px 0px 8px 0px;
`

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 13px;
  color: #4e4e4e;
`

const Title = styled(Typography.Title)`
  font-size: 20px;
`

const ColorBox = styled.div<any>`
  width: 15px;
  height: 15px;
  border-radius: 5px;
  border: 1px solid gray;
  background-color: ${({code}) => code};
`

export default Cart
